/* Main container for the PDF viewer */
.pdf-viewer-container {
  text-align: center;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  background: rgba(255, 255, 255, 1);
  border-radius: 20px;
  box-shadow: 0 40px 30px rgba(6, 23, 51, 0.5); /* Heavy shadow below */
  z-index: 9000;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Space out the content vertically */
  overflow: hidden;
  box-sizing: border-box;
  padding-top: 30px;
}

/* Close button styling */
.pdf-viewer-close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  color: white;
  background-color: #3C4655;
  border-radius: 20px;
  padding: 8px; 
  z-index: 10000; /* Ensure it's on top */
}

/* Container for the pdf selection buttons */
.pdf-selection-buttons {
  display: flex;
  justify-content: start;
  gap: 10px;
  margin-bottom: 10px;
  margin-right: 30px;
  margin-top: 10px;
  padding-right: 10px;
  padding-left: 10px;
}

/* Individual PDF selection button */
.pdf-selection-btn {
  background-color: #ccc;
  color: rgb(0, 0, 0);
  padding: 10px;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  font-weight: normal;
  transition: background-color 0.3s ease;
}

.pdf-selection-btn.selected {
  background-color: #3C4655;
  color: white;
  font-weight: bold;
}

/* Container for the canvas */
.pdf-canvas-container {
  display: flex;
  justify-content: center;
  flex-grow: 1; /* Allow this container to take remaining space */
  overflow: hidden;
}

/* Wrapper for the scrollable PDF canvas */
.pdf-canvas-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto; /* Enable vertical scroll only for the PDF canvas */
  width: 100%;
  max-height: calc(100vh - 100px); /* Make sure the canvas does not overlap with buttons */
  background: white;
}

.pdf-canvas {
  width: auto;
  max-width: 100%;
  object-fit: contain;
}

/* Pagination controls */
.pdf-pagination-controls {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-top: 10px;
  padding-bottom: 10px;
  flex-shrink: 0; /* Prevent shrinking of pagination controls */
}

/* Styling for pagination text and input */
.pdf-pagination-text {
  font-size: 16px;
}

.pdf-page-input {
  width: 50px;
  text-align: center;
}

/* Navigation buttons container */
.pdf-navigation-btns {
  display: flex;
  gap: 10px;
  justify-content: center;
  padding-top: 10px; /* Ensure padding at the top to separate from canvas */
  position: relative;
  flex-shrink: 0; /* Ensure buttons are not pushed down */
}

/* Mobile-specific adjustments */
@media (max-width: 768px) {
  .pdf-selection-buttons {
    justify-content: center; /* Center the buttons on small screens */
    margin-right: 0; /* Remove the extra margin */
    margin-top: 15px;
    gap: 8px; /* Reduced gap between buttons */
  }

  .pdf-selection-btn {
    font-size: 0.5rem; /* Smaller text size on mobile */
    padding: 8px; /* Slightly reduce padding on mobile */
    width: 100px; /* Limit the width of the buttons on mobile */
    text-overflow: ellipsis; /* Truncate text with ellipsis */
  } 
}
