.inputWrapper {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 10px;
    position: relative;
}

.questionInputTextArea {
    flex-grow: 1;
    background-color: white;
    border: 3px solid #d3d3d3;
    border-radius: 20px;
    padding: 12px;
    padding-right: 150px; /* Space for buttons inside */
    width: 100%; /* Ensure the input takes full width */
    box-sizing: border-box; /* Make padding count within the width */
}

.buttonBox {
    display: flex;
    align-items: center;
    position: absolute;
    right: 10px;
    padding-right: 10px;
    gap: 10px;
    /* By default, buttons are aligned horizontally */
}

.primaryButton {
    border-radius: 20px;
    padding: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70px; /* Increased width */
    height: 40px;
}

.microphoneButton {
    background-color: #EEEEEE; /* Light grey background for microphone button */
}

.activeMic {
    background-color: red; /* Change background to red when active */
}

.sendButton {
    background-color: #707F97; /* Darker color for send button */
}

.primaryButtonDisabled {
    opacity: 0.4;
    pointer-events: none;
}

/* Mobile styles */
@media (max-width: 600px) {
    .inputWrapper {
        flex-direction: row; /* Stack input and buttons vertically */
        gap: 10px; /* Space between input and buttons */
    }

    .questionInputTextArea {
        padding-right: 0; /* Remove padding space for buttons */
    }

    .buttonBox {
        flex-direction: column; /* Stack the buttons vertically */
        position: relative; /* Adjust positioning */
        right: 0px;
        padding-right: 0px;
    }
}
