.chatContainer {
    display: flex;
    flex-direction: column; /* Stack content vertically */
    width: 100%; /* Ensure it takes the full width of its parent */
    height: 105vh; /* Make it take the full viewport height */
    max-width: 100%; /* Remove the max-width restriction to allow full width */
    margin: 0 auto; /* Center the container horizontally */
    background-color: #707F97; /* Background color */
    padding: 20px; /* Add padding for inner spacing */
    border-radius: 20px; /* Rounded corners */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Shadow for depth */
    box-sizing: border-box; /* Ensure padding doesn't affect width/height calculation */
    overflow: hidden; /* Ensure no overflow beyond container */
    height: 90vh;
}

.titleBox {
    background: #3C4655;
    border-radius: 20px;
    color: white;
    padding: 10px 15px;
    flex-grow: 1;
    margin: 0 auto; /* Center it horizontally */
    display: inline-block;
    text-align: center;
    max-width: 100%; /* Ensure it doesn't exceed container width */
}

.chatbutton {
    background-color: white;
    color: #3C4655;
    padding: 10px 30px; /* Horizontal padding only to match the title box */
    transition: background-color 0.3s, color 0.3s;
    border-radius: 20px;
    box-shadow: 0 0 0 10px #3C4655; /* Blue outline */
    display: flex;
    align-items: center; /* Vertically align text */
    justify-content: center; /* Center text horizontally */
    max-width: 200px; /* Ensure the button doesn't become too wide */
    margin-right: 20px;
}

.chatbutton:hover {
    background-color: #707F97;
    color: white;
}

.chatBox {
    display: flex;
    flex-direction: column; /* Stack content vertically */
    gap: 10px; /* Space between components */
    position: relative; /* For absolute positioning of PdfViewer */
    flex-grow: 1; /* Take the remaining space in chatContainer */
    height: 100%; /* Full height of chatContainer */
    overflow-y: auto; /* Allow overflow with scroll */
}

.pdfViewerWrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2; /* Ensure PDF viewer is above other elements */
    height: 100%; /* Takes up the top half of the chatBox */
}

.chatContent {
    display: flex;
    flex-direction: column; /* Stack video and chat content vertically */
    flex: 1; /* Ensure it takes up the remaining space */
    height: 50%; /* Ensure it fills the remaining space */
    overflow: hidden; /* Prevent overflow */
}

.videoArea {
    width: 100%; /* Full width of the container */
    height: 50%; /* Takes up the top half of the chatContent */
    background: #ffffff; /* Background color for video area */
    border-top-left-radius: 20px; /* Apply border radius to top-left corner */
    border-top-right-radius: 20px; /* Apply border radius to top-right corner */
    box-sizing: border-box; /* Ensure padding doesn't affect the size */
    margin: 0; /* Optional: Set margin to control outer spacing */
}

.textArea {
    display: flex;
    flex-direction: column;
    height: 50%; /* Take up available height */
    overflow-y: auto; /* Allow scrolling */
    background-color: white;
    padding: 10px;
    border-bottom-left-radius: 20px; /* Apply border radius to bottom-left corner */
    border-bottom-right-radius: 20px; /* Apply border radius to bottom-right corner */
}

.messageBubble {
    padding: 10px;
    border-radius: 20px;
    margin: 5px 0;
    max-width: 75%; /* Limit width for better appearance */
    word-wrap: break-word; /* Break long words */
}

.userMessage {
    background-color: #EEEEEE; /* Light grey background for user messages */
    align-self: flex-end; /* Align to the right */
    color: black;
    text-align: right; /* Right-align the text inside the message bubble */
}

.systemMessage {
    background-color: #707F97; /* Light green background for system messages */
    color: white;
    align-self: flex-start; /* Align to the left */
    text-align: left; /* Right-align the text inside the message bubble */
}

.numberedText {
    cursor: pointer; /* Indicate clickable text */
    margin: 5px 0;  /* Spacing between lines */
    color: white;    /* Change text color if desired */
}

.questionItem {
    cursor: pointer;
    color: white;
    margin-bottom: 0.5rem;
    display: inline-block;
    padding: 15px 15px;
    border-radius: 20px;
    text-align: center;
    width: 100%;
    box-sizing: border-box;
}

.questionItem.even {
    background-color: #707F97;
}

.questionItem.odd {
    background-color: #3C4655;
}

/* Media query adjustments */
@media (max-width: 768px) {
    .titleBox {
        flex-grow: 1;
        margin: 0 auto; /* Center it horizontally */
        display: inline-block;
        text-align: center;
        font-size: 0.7rem;
    }

    .chatbutton {
        padding: 10px 20px; /* Horizontal padding only to match the title box */
        max-width: 100px; /* Ensure the button doesn't become too wide */
        font-size: 0.6rem;
    }

    .textArea {
        font-size: 0.6rem;
    }
    
    .questionItem {
        padding: 10px 10px;
        font-size: 0.6rem;
    }
}
