/* styles.css or your preferred CSS file */
  .loading-circle-container {
    position: absolute; /* Ensures it stays inside the video container */
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); /* Centers the spinner */
  }
  
  .spinner {
    border: 4px solid #000000; /* Light grey */
    border-top: 4px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  