/* Background Container */
.background-container {
  background-color: #EEEEEE; /* Set background to #EEEEEE */
  padding: 0; /* Remove any padding that could cause vertical spacing */
  min-height: 100vh; /* Ensure the background container takes up the full height */
  display: flex;
  flex-direction: column; /* Stack content vertically */
  min-height: 100vh; /* Full viewport height */
  overflow: hidden; /* Prevent page overflow */
}

.content-wrapper {
  flex: 1; /* Allow content to grow and push the footer down */
  overflow-y: auto
}

/* Title Box */
.titleBox {
  background: #3C4655; /* Background color */
  border-radius: 20px; /* Border radius */
  color: white; /* Text color */
  padding: 20px; /* Add padding for better spacing */
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Bullet Points */
.bullets {
  list-style-type: disc;
  text-align: left;
  color: #000;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 0 10px;
}

/* Persona Box Layout */
.box-container {
  padding: 10px;
  background-color: #707F97; /* Ensure the boxes have a grey background */
  border-radius: 10px; /* Add rounded corners to the box */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  transition: transform 0.2s ease; /* Add smooth hover effect */
}

/* Hover Effect for Persona Boxes */
.box-container:hover {
  transform: translateY(-10px); /* Lift the box on hover */
}

/* Persona Image Styles */
.imageContainer {
  flex-shrink: 0; /* Prevent image from shrinking */
  margin-bottom: 10px; /* Add space between the image and text */
}

.personaImage {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

/* Guide Button Custom Style */
.button.is-small {
  color: #707F97; /* Grey text */
  background-color: white; /* White background */
  border-radius: 20px; /* Border radius of 20px */
  border: 1px solid #707F97; /* Optional: Add a border to match text color */
  padding: 5px 20px; /* Add padding to left and right of text */
  font-weight: bold; /* Make the text bold */
  text-transform: uppercase; /* Optional: Makes the text uppercase for a bolder look */
  transition: background-color 0.3s ease, transform 0.2s ease; /* Smooth transition */
}

/* Hover Effect */
.button.is-small:hover {
  background-color: #707F97; /* Change background on hover */
  color: white; /* Change text color on hover */
  cursor: pointer; /* Change cursor to pointer */
}

/* Active/Pressed Effect */
.button.is-small:active {
  transform: scale(0.95); /* Slightly shrink when pressed */
  background-color: #5e6a7f; /* Darker background when pressed */
}

/* Content Centering Below Title */
.center-content {
  padding-left: 10%;
  padding-right: 10%;
  background-color: #EEEEEE; /* Set background to #EEEEEE for everything under "Live Persona Demos" */
  margin-top: 20px; /* Start from the top and give some space from the title */
  flex-grow: 1; /* Allow the content to grow and push the footer to the bottom */  
}

/* Footer */
.footer {
  background-color: #EEEEEE;
  color: black;
  padding: 10px 0;
  margin-top: auto; /* Push footer to the bottom */
  text-align: center;
}

/* Ensure Two Boxes Per Row */
.columns.is-multiline {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly; /* Distribute boxes evenly */
  gap: 10px;
  margin-top: 10px;
}

.column {
  flex: 1 1 calc(50% - 20px); /* Take 50% width minus spacing */
  max-width: calc(50% - 20px);
  box-sizing: border-box;
}

/* Title Text Responsive Adjustments */
.title {
  font-size: 1.5rem; /* Default size for larger screens */
}

.box-title {
  font-size: clamp(1rem, 2.5vw, 2rem); /* Dynamically adjust font size */
  line-height: 1.2; /* Maintain good vertical spacing */
  white-space: normal; /* Allow wrapping */
  overflow: visible; /* Show all content */
  max-width: 100%; /* Prevent overflow outside the box */
  color: white; /* Ensure text color matches the design */
}

@media (max-width: 768px) {
  .title {
    font-size: 1rem; /* Smaller size for mobile screens */
    white-space: nowrap; /* Prevent wrapping */
    overflow: hidden;
    text-overflow: ellipsis; /* Truncate if necessary */
  }

  .box-title {
    font-size: 1rem !important; /* Smaller size for smaller screens */
    line-height: 1.2;
  }

  .column {
    flex: 0 0 calc(50% - 5px); /* Adjust spacing for smaller screens */
    max-width: calc(50% - 5px);
  }
}
